
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import dayjs from "dayjs";
import StandardFlag from "@/components/scope/standard-flag.vue";
import { emptyToLine, splitThousands } from "@/utils/common";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import t from "@common/src/i18n/t";
const learning = namespace("learning");
@Component({
  components: { TableEnableFlag, StandardFlag }
})
export default class LearningCategoryList extends Vue {
  @learning.Action queryLearnClassList;
  @learning.Action changeLearnClassOrder;
  @learning.Action createLearnClass;
  @learning.Action delLearnClass;
  @learning.Action updateLearnClassName;
  dayjs = dayjs;
  splitThousands = splitThousands;
  emptyToLine = emptyToLine;
  searchForm = {
    className: ""
  };
  insertForm: any = {
    className: ""
  };
  insertFormRules: any = {
    className: [{ required: true, message: t("setting.class-name-cannot-empty"), trigger: "blur" }]
  };
  dialogFormVisible: boolean = false;
  tableLoading: boolean = false;
  contentClassList: any[] = [];
  contentClassListCopy: any[] = [];
  parentRow: any = {};
  total: number = 0;
  tipsTitle: any = t("setting.new-level-classification");
  categoryList: any[] = [];
  brandList: any[] = [];
  created() {
    this.loadListData();
  }
  async loadListData(callback?) {
    this.tableLoading = true;
    let data = await this.queryLearnClassList({
      ...this.searchForm,
      pageNum: 1,
      pageSize: 999
    });
    this.total = data.data.total;
    this.contentClassList = this.addlevelFlag(this.$safeValue(data.data, "list", []));
    this.contentClassListCopy = this.addlevelFlag(this.$safeValue(data.data, "list", []));
    this.tableLoading = false;
    console.log(this.contentClassList);

    callback && callback();
  }
  addlevelFlag(list) {
    const f = function(list, level, parentCode) {
      list.forEach(item => {
        item.level = level;
        item.parentCode = parentCode;
        // item.source = "1";
        // item.isCould = true;
        if (item.childrenList && item.childrenList.length > 0) {
          f(item.childrenList, level + 1, item.classCode);
        }
      });
    };
    f(list, 1, null);
    return list;
  }
  submitForm(formName) {
    (this.$refs[formName] as any).validate(valid => {
      if (valid) {
        this.addNode();
      } else {
        console.log("error submit!!");
      }
    });
  }
  addNode() {
    if (!Object.keys(this.parentRow).length) {
      // 添加根节点
      // this.partsCategoryList.push(newNode);
      this.handleInsert(this.insertForm.className);
    } else {
      this.handleInsert(this.insertForm.className, this.parentRow.classCode);
    }
  }
  handleAddCategory(row?) {
    if (row.level >= 2) {
      return;
    }
    if (row) {
      this.parentRow = row;
      this.tipsTitle = t("setting.add-subcategories");
    } else {
      this.parentRow = {};
      this.tipsTitle = t("setting.new-level-classification");
    }
    this.dialogFormVisible = true;
  }
  // 新增
  handleInsert(name, parentCode = null) {
    let level = this.parentRow.level ? this.parentRow.level * 1 + 1 + "" : "1";
    this.createLearnClass({
      className: this.insertForm.className,
      classLevel: level,
      parentCode: parentCode
    }).then(() => {
      this.loadListData();
      this.dialogFormVisible = false;
      this.insertForm.className = "";
      this.$message.success(t("finance.new-success"));
      if (parentCode) {
        this.loadListData(() => {
          this.$nextTick(() => {
            (this.$refs["table"] as any).toggleRowExpansion(this.parentRow, true);
          });
        });
      } else {
        this.loadListData();
      }
    });
  }
  saveRow(row) {
    if (row.originData.className === row.className) {
      row.input = false;
      return;
    }
    this.updateLearnClassName({
      classCode: row.classCode,
      className: row.className
    })
      .then(data => {
        row.input = false;
        this.$message.success(t("base.update-success"));
      })
      .catch(() => {
        this.loadListData();
      });
  }
  editRow(row) {
    if (row.source === "1") {
      return;
    }
    let data = JSON.parse(JSON.stringify(row));
    row.originData = data;
    if (typeof row.input !== "boolean") {
      this.$set(row, "input", true);
    } else {
      row.input = true;
    }
    this.$nextTick(() => {
      console.log(this.$refs["input" + row.classCode]);
      (this.$refs["input" + row.classCode] as any).focus();
    });
  }
  handleSearch(data) {
    this.loadListData();
  }
  handleSort(scope, sortType) {
    if (this.searchForm.className !== "") {
      this.$message.warning(t("v210831.query-status-does"));
      return;
    }
    let params = {
      classLevel: scope.row.level,
      currentClassCode: scope.row.classCode,
      operateType: sortType,
      parentCode: scope.row.parentCode
    };
    this.changeLearnClassOrder(params).then(() => {
      this.$message.success(t("v210831.sorting-succeeded"));
      this.loadListData();
    });
  }
  // 删除
  handleDelete(row) {
    if (row.newsNum > 0) {
      this.$message.error("该分类下有文章，清空文章后才可删除该分类。");
      return;
    }
    this.delLearnClass({ classCode: row.classCode }).then(() => {
      this.$message.success(t("base.delete-success"));
      this.loadListData();
    });
  }
}
